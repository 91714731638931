<template>
  <div>
    <router-view />
    <Tabbar />
  </div>
</template>
<script>
import Tabbar from '@/component/Tabbar'

export default {
  name: 'Home',
  components: {
    Tabbar

  },
  data() {
    return {}
  }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
// @import './index.less';
</style>
